import logoLogin from "../Assets/Media/logoLogin.png"
import logo from '../Assets/Media/logo.png';
import noData from './../Assets/Media/noData.png'
import deleteIcon from "../Assets/Media/deleteAccount.gif"
import bgAuth from '../Assets/Media/bgAuth.png';
import uploadImage from '../Assets/Media/uploadImage.png';
import dashboardUser from '../Assets/Media/dashboardUser.png';
import profileUser from '../Assets/Media/profile.png';
import engFlag from '../Assets/Media/flag/eng.png';
import indiaFlag from '../Assets/Media/flag/india.png';

export const MediaEndpoints = {
    logoLogin,
    logo,
    noData,
    deleteIcon,
    bgAuth,
    uploadImage,
    dashboardUser,
    profileUser,
    flags:{
        engFlag,
        indiaFlag
    }
}
export const mediaUrl = ""

export const colorCode = {
    defaultDarkColor:"#8b0000",
    defaultLightColor:"#bbcae3",
}