export const apiEndpoints = {
    loginUrl:`admin/auth/login`,
    signUrl:`admin/auth/signup`,
    forgetUrl :'admin/auth/forgot_password',
    otpVerifyUrl : "admin/auth/verify_otp",
    resendOtpUrl : "admin/auth/resend_otp",
    changePasswordUrl : "admin/auth/change_password",
    resetPasswordUrl : "admin/auth/reset_password",
    profileUrl : "admin/auth/details",
    commonContentUrl : "common/common_content",
    commonContentUpdateUrl : "admin/common/common_content",
    faqGetUrl :"common/questions", 
    faqCommonUrl : "admin/common/question",
    userGetUrl : "admin/user/list", 
    userDetailsUrl : "admin/user/details", 
    userStatusUrl : "admin/user/status",
    userDashboardUrl : "admin/user/dashboard",
}